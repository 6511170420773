import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

i18n
  .use(backend)
  .use(initReactI18next as any) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: process.env.REACT_APP_ROUTE_BASE_PATH
        ? `${process.env.REACT_APP_ROUTE_BASE_PATH}/locales/{{lng}}/{{ns}}.json`
        : `/locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: false,
      crossDomain: false,
    },
    supportedLngs: ['en', 'cn'],
    // lng: "jp",
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
