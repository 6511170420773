import { createSlice } from '@reduxjs/toolkit';
import { InferenceStore } from './types';

export const intitialInfereceStore: InferenceStore = {
  shownStores: {},
  excludedMonths: [],
};

export const InferenceSlice = createSlice({
  name: 'Detail slice',
  initialState: intitialInfereceStore,
  reducers: {
    setShownStores: (store, action) => {
      store.shownStores = action.payload;
    },
  },
});

// Actions
export const inferenceActions = InferenceSlice.actions;

export default InferenceSlice.reducer;
