import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { DetailStore } from './detail.types';

export const initialDetailState: DetailStore = {
  records: [],

  summary: {
    customer: 0,
    turnover: 0,
    product: 0,
  },

  detail: {
    by_weekday: [],
    by_product_category: [],
    customer: {
      by_age: [],
    },
  },
  from: moment().subtract(2, 'month').startOf('month'),
  to: moment().subtract(2, 'month').endOf('month'),
  loading: false,
};

export const DetailSlide = createSlice({
  name: 'Detail slice',
  initialState: initialDetailState,
  reducers: {
    setDetail: (state, { payload }) => {
      state.records = payload.records;
      state.detail = payload.detail;
      state.summary = payload.summary;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },

    setDate: (state, { payload: { from, to } }) => {
      state.from = from;
      state.to = to;
    },
  },
});

// Actions
export const detailActions = DetailSlide.actions;

export default DetailSlide.reducer;
