import { combineReducers } from 'redux';

import detail from './detail/detail.reducer';
import prediction from './prediction/prediction.reducer';
import inference from './inference/inference.reducer';
import train from './train/train.reducer';
import user from './user/user.reducer';

export default combineReducers({
  detail,
  prediction,
  inference,
  train,
  user,
});
