import { createSlice } from '@reduxjs/toolkit';
import { UserStore } from './user.types';

export const initialUserStore: UserStore = {};

export const UserSlice = createSlice({
  name: 'User slice',
  initialState: initialUserStore,
  reducers: {
    setUser: (store, { payload }) => {
      store.user = payload;
    },
  },
});

// Actions
export const userActions = UserSlice.actions;

export default UserSlice.reducer;
