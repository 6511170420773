import { createSlice } from '@reduxjs/toolkit';
import { TrainStore } from './types';

export const intitialInfereceStore: TrainStore = {
  loading: false,
};

export const TrainSlice = createSlice({
  name: 'Detail slice',
  initialState: intitialInfereceStore,
  reducers: {
    setJob: (state, { payload }) => {
      state.job = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

// Actions
export const trainActions = TrainSlice.actions;

export default TrainSlice.reducer;
