import { createStore, applyMiddleware, compose } from 'redux';
import createSaga from 'redux-saga';
import reducer from './reducer';
import saga from './saga';

const sagaMiddleware = createSaga();

export const initializeStore = (initialState = {}) => {
  const middlewares = [sagaMiddleware];
  const enhancer = compose(applyMiddleware(...middlewares));

  const store = createStore(reducer, initialState, enhancer);
  sagaMiddleware.run(saga);
  return store;
};
