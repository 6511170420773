import { createSlice } from '@reduxjs/toolkit';
import { PredictionStore } from './prediction.types';

export const initialPredictionStoreState: PredictionStore = {
  clusterDone: false,
  correlated: [],
  stores: [],
  filteredStores: [],
  view: 'form',
  formView: 'existing',
  report: {},
  mapFeatures: [],
  storeFeatures: [],
  loading: false,
  prediction: {},
};

export const PredictionSlide = createSlice({
  name: 'Detail slice',
  initialState: initialPredictionStoreState,
  reducers: {
    setClusterDone: (state, { payload }) => {
      state.clusterDone = payload;
    },
    setClusters: (state, { payload }) => {
      state.clusters = payload;
    },
    setStores: (state, { payload }) => {
      state.stores = payload;
    },
    setFilteredStore: (state, { payload }) => {
      state.filteredStores = payload;
    },
    setView: (state, { payload }) => {
      state.view = payload;
    },
    setFormView: (state, { payload }) => {
      state.formView = payload;
    },
    setSeasonal: (state, { payload }) => {
      state.seasonal = {
        start: payload.start,
        end: payload.end,
      };
    },
    setTrend: (state, { payload }) => {
      state.trend = {
        start: payload.start,
        end: payload.end,
      };
    },
    setTarget: (state, { payload }) => {
      state.target = {
        start: payload.start,
        end: payload.end,
      };
    },

    setLagged: (state, { payload }) => {
      state.lagged = payload;
    },

    setReport: (state, { payload }) => {
      state.report = payload;
    },

    setMapFeatures: (state, { payload }) => {
      state.mapFeatures = payload;
    },

    setStoreFeatures: (state, { payload }) => {
      state.storeFeatures = payload;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },

    setPrediction: (state, { payload }) => {
      state.prediction = payload;
    },
  },
});

// Actions
export const predictionActions = PredictionSlide.actions;

export default PredictionSlide.reducer;
