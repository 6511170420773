import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import { Provider as StoreProvider } from 'react-redux';
import './App.css';
import { Spinner } from './components/Spinner';
import { initializeStore } from './store';
import 'react-tabs/style/react-tabs.css';
import './i18n';
import './index.d';

const BASE_PATH = process.env.REACT_APP_ROUTE_BASE_PATH || '/';

const store = initializeStore();

const WaitingComponent = ({
  Component,
  path,
  ...props
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
  path: string;
}) => (
  <Suspense
    fallback={
      <div
        className="flex items-center align-middle"
        style={{ width: '100vw', height: '100vh' }}
      >
        <Spinner />
      </div>
    }
  >
    <Component path={path} {...props} />
  </Suspense>
);

const Dashboard = lazy(() => import('./views/Dashboard'));
const Detail = lazy(() => import('./views/Detail'));
const DetailMap = lazy(() => import('./views/DetailMap'));
const NewStore = lazy(() => import('./views/NewStore'));
const Login = lazy(() => import('./views/Login'));
const JobList = lazy(() => import('./views/JobList'));
const SelectOptions = lazy(() => import('./views/SelectOption'));
const Train = lazy(() => import('./views/Train'));
const Forecast = lazy(() => import('./views/Forecast'));
const ModelTrainingList = lazy(() => import('./views/ModelTrainingList'));
const TrainSelect = lazy(() => import('./views/TrainingSelect'));
const ForecastSelect = lazy(() => import('./views/ForecastSelect'));

function App(): JSX.Element {
  return (
    // <Auth0Provider
    //   domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
    //   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
    //   redirectUri={window.location.origin}
    // >
    <StoreProvider store={store}>
      <Suspense fallback={<Spinner />}>
        <Router basepath={BASE_PATH}>
          {/* <WaitingComponent Component={Dashboard} path="/" />
          <WaitingComponent Component={Detail} path="/detail" />
          <WaitingComponent Component={DetailMap} path="/detail/:region/map" />
          <WaitingComponent
            Component={Detail}
            path="/detail/:region/store/:store_id"
          /> */}

          <WaitingComponent Component={Login} path="/login" />
          {/* <WaitingComponent Component={SelectOptions} path="/" /> */}
          <WaitingComponent Component={SelectOptions} path="/" />
          <WaitingComponent Component={Train} path="/train" />
          <WaitingComponent Component={Train} path="/model/:id" />
          <WaitingComponent Component={TrainSelect} path="/train-select" />
          <WaitingComponent Component={ModelTrainingList} path="/models" />
          <WaitingComponent
            Component={ForecastSelect}
            path="/forecast-select"
          />
          <WaitingComponent Component={Forecast} path="/forecast" />
          <WaitingComponent Component={Forecast} path="/forecast/:id" />
          <WaitingComponent Component={NewStore} path="/new-store" />
          <WaitingComponent Component={JobList} path="/jobs" />
        </Router>
      </Suspense>
    </StoreProvider>
    // </Auth0Provider>
  );
}

export default App;
