/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForkEffect } from '@redux-saga/core/effects';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { axiosInstance } from '../../services/apiClient';
import { IStore } from '../types';
import { detailActions } from './detail.reducer';

const types = {
  GET_STORE_DETAIL: 'GET_STORE_DETAIL',
};

interface GetStoreDetailAction {
  type: string;
  payload: { region: string; store_id: string };
}

export const sagaActions = {
  getStoreDetail: ({
    region,
    store_id,
  }: {
    region: string;
    store_id: string;
  }): GetStoreDetailAction => ({
    type: types.GET_STORE_DETAIL,
    payload: {
      region,
      store_id,
    },
  }),
};

function* getStoreDetailSaga(action: GetStoreDetailAction): any {
  const { payload } = action;
  console.log('Get store detail in saga');

  try {
    yield put(detailActions.setLoading(true));
    const { from, to } = yield select((store: IStore) => store.detail);
    const res = yield call(
      axiosInstance,
      `/api/v1/region/${payload.region}/store/${
        payload.store_id
      }?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}`
    );

    // console.log({ res });
    // console.log('data: ', {
    //   data: res?.data?.data,
    // });
    yield put(detailActions.setDetail(res?.data?.data ?? {}));
  } catch (error) {
    console.log('Get store detail error', {
      error,
    });
  }
  yield put(detailActions.setLoading(false));
}

export default function* detailSaga(): Generator<ForkEffect<void>> {
  yield takeLatest(types.GET_STORE_DETAIL, getStoreDetailSaga) as any;
}
