/* eslint-disable @typescript-eslint/no-explicit-any */
import { ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../services/apiClient';
import { IStore } from '../types';
import { predictionActions } from './prediction.reducer';

const types = {
  GET_NEW_STORE_CLUSTERED: 'GET_NEW_STORE_CLUSTERED',
  UPDATE_PREDICTION: 'UPDATE_PREDICTION',
};

export const predictionSagaActions = {
  getNewStoreCluster: (payload: any): any => ({
    type: types.GET_NEW_STORE_CLUSTERED,
    payload,
  }),
  updatePrediction: (payload: any): any => ({
    type: types.UPDATE_PREDICTION,
    payload,
  }),
};

function* getNewStoreClustered({ payload }: any): any {
  try {
    yield put(predictionActions.setLoading(true));
    const { target, trend, seasonal } = yield select(
      (store: IStore) => store.prediction
    );
    const response = yield axiosInstance.post('/api/v1/clustering', payload);

    yield put(predictionActions.setMapFeatures(payload.selectedMapFeatures));
    yield put(
      predictionActions.setStoreFeatures(payload.selectedStoreFeatures)
    );
    yield put(predictionActions.setClusters(response.data.data));
    yield put(predictionActions.setClusterDone(true));
    yield put(
      predictionSagaActions.updatePrediction({
        user_input: {
          ...payload,
          region: 'Beijing',
          target,
          trend,
          seasonal,
        },
        prediction: {
          custering: response.data.data,
        },
      })
    );
  } catch (error) {}
  yield put(predictionActions.setLoading(false));
}

function* updatePredictionSaga({ payload }: any): any {
  try {
    yield put(predictionActions.setLoading(true));
    const prediction = yield select(
      (store: IStore) => store.prediction.prediction ?? {}
    );
    const createPredictionRes = yield axiosInstance.post(
      '/api/v1/prediction' + (prediction.id ? `/${prediction.id}` : ''),
      {
        user_input: {
          ...(prediction.user_input ?? {}),
          ...(payload.user_input ?? {}),
        },
        prediction: {
          ...(prediction.prediction ?? {}),
          ...(payload.prediction ?? {}),
        },
      },
      { withCredentials: true }
    );

    yield put(predictionActions.setPrediction(createPredictionRes.data.data));
  } catch (error) {}
  yield put(predictionActions.setLoading(false));
}

export default function* predictionlSaga(): Generator<ForkEffect<void>> {
  yield takeLatest(types.GET_NEW_STORE_CLUSTERED, getNewStoreClustered) as any;
  yield takeLatest(types.UPDATE_PREDICTION, updatePredictionSaga) as any;
}
